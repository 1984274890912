import { createSlice } from '@reduxjs/toolkit';

const initialState = { isMobile: false };

const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    turnMobile: (state, action) => {
      state.isMobile = true;
    },
    turnNotMobile: (state, action) => {
      state.isMobile = false;
    },
  },
});

export const { turnMobile, turnNotMobile } = mobileSlice.actions;
export default mobileSlice.reducer;

import React, { useRef } from 'react';
import '../stylesheets/homeMobile.css';
import Greeting from '../components/home/greeting';
import About from '../components/home/about';
import Codes from '../components/home/codes';
import Profile from '../components/home/profile';
import Sidebar from '../components/homeMobile/sidebar';

const HomeMobile = () => {
  const greetingRef = useRef(null);
  const aboutRef = useRef(null);
  const codesRef = useRef(null);
  const profileRef = useRef(null);
  const currentRef = useRef(null);

  const executeScroll = (ref) => {
    switch (ref) {
      case 'greeting':
        currentRef.current = greetingRef;
        break;
      case 'about':
        currentRef.current = aboutRef;
        break;

      case 'codes':
        currentRef.current = codesRef;
        break;

      case 'profile':
        currentRef.current = profileRef;
        break;
      default:
        currentRef.current = greetingRef;
    }
    if (currentRef.current != null) {
      currentRef.current.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className="home-mobile-container">
      <Sidebar scroll={executeScroll} />
      <div ref={greetingRef}>
        <Greeting />
      </div>
      <div ref={aboutRef}>
        <About />
      </div>
      <div ref={codesRef}>
        <Codes />
      </div>
      <div ref={profileRef}>
        <Profile />
      </div>
    </div>
  );
};

export default HomeMobile;

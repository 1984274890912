import React, { useRef } from 'react';
import { tags, tagVariables } from '../../assets/data/tags';
import '../../stylesheets/projectsList.css';

const Tags = ({ filters, setFilters }) => {
  const tagBarEl = useRef(null);

  const handleTags = (name) => {
    const isIncluded = filters.includes(name);
    if (isIncluded) {
      const newFilters = filters.filter((tag) => tag !== name);
      setFilters([...newFilters]);
    } else {
      setFilters([...filters, name]);
    }
  };

  const selectedTag = (name) => {
    const isIncluded = filters.includes(name);
    if (isIncluded) {
      return 'highlight';
    } else {
      return '';
    }
  };

  const mapTags = (tagVariables, tags) => {
    return tagVariables.map((key) => {
      const tag = tags[key];
      if (tag) {
        return (
          <li
            key={tag.name + '-tag'}
            onClick={() => handleTags(tag.name)}
            className={selectedTag(tag.name)}
          >
            <div>{tag.src ? <img src={tag.src} alt={tag.alt} /> : null}</div>
            <span>{tag.name}</span>
          </li>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="projects-list-tags">
      <div>
        <ul ref={tagBarEl} className="tags">
          {mapTags(tagVariables, tags)}
        </ul>
      </div>
    </div>
  );
};

export default Tags;

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Home from './pages/home';
import Projects from './pages/projectsList';
import Playground from './pages/playground';
import Paintings from './pages/paintings';

const Routes = () => {
  return (
    <main>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/codes">
          <Projects />
        </Route>
        <Route exact path="/playground">
          <Playground />
        </Route>
        <Route exact path="/paintings">
          <Paintings />
        </Route>
        <Redirect to="/" />
      </Switch>
    </main>
  );
};

export default Routes;

import React, { useState } from 'react';
import '../../stylesheets/playground/quiz.css';

import QuizData from '../../assets/data/quiz.json';
import { useSelector } from 'react-redux';

const Quiz = (props) => {
  const questions = QuizData.quizData;
  const [questionsIdx, setQuestionsIdx] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const isMobile = useSelector((state) => state.mobile.isMobile);

  let { question, answer } = questions[questionsIdx];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (answer === selectedAnswer) {
      setScore((score) => (score += 1));
    }
    if (questionsIdx === questions.length - 1) {
      alert(`you've completed the quiz. your score is ${score}`);
      reset();
    } else {
      setQuestionsIdx((idx) => (idx += 1));
      setSelectedAnswer('');
    }
  };

  const reset = () => {
    setQuestionsIdx(0);
    setScore(0);
    setSelectedAnswer('');
  };

  const handleFocus = (e) => {
    setSelectedAnswer(e.target.id);
  };

  return (
    <form
      className={`quiz-container ${isMobile ? 'quiz-mobile' : ''}`}
      id="quiz"
      onSubmit={handleSubmit}
    >
      <div className="quiz-header">
        <h1 id="question">{question}</h1>
        <ul>
          {['a', 'b', 'c', 'd'].map((id, idx) => {
            return (
              <li key={id}>
                <input
                  type="radio"
                  id={id}
                  className="answer"
                  name="answer"
                  checked={selectedAnswer === id}
                  onFocus={handleFocus}
                />
                <label id={`q-${id}`} htmlFor={`${id}`}>
                  {questions[questionsIdx][id]}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
      <button id="submit">Submit</button>
    </form>
  );
};

export default Quiz;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { remove, edit } from '../../../redux/notesSlice';

const Note = (props) => {
  const { note, idx, key } = props;
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(false);
  const [text, setText] = useState(note);

  return (
    <div className="note" id={`note-${idx}-${key}`}>
      <div className="tools">
        <button onClick={() => dispatch(remove(idx))}>delete</button>
        {!hidden ? (
          <button
            onClick={() => {
              setHidden(true);
            }}
          >
            edit
          </button>
        ) : (
          <button
            onClick={() => {
              setHidden(false);
              dispatch(edit({ idx, text }));
            }}
          >
            save
          </button>
        )}
      </div>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
      ></textarea>
      <div className={`main-note ${hidden ? 'hidden' : ''}`}>{text}</div>
    </div>
  );
};

export default Note;

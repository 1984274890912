import { createSlice } from '@reduxjs/toolkit';

const initialState = { color: 'black', isHovering: false };

const cursorSlice = createSlice({
  name: 'cursor',
  initialState,
  reducers: {
    enter: (state, action) => {
      state.isHovering = true;
    },
    exit: (state, action) => {
      state.isHovering = false;
    },
    switchColor: (state, action) => {
      state.color = action.payload;
    },
  },
});

export const { enter, exit, switchColor } = cursorSlice.actions;
export default cursorSlice.reducer;

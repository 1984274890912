import React from 'react';
import '../../stylesheets/projectsListMobile.css';
import { tags, tagVariables } from '../../assets/data/tags';
import { useSelector, useDispatch } from 'react-redux';
import { close } from '../../redux/navMobileSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const TagsDropdown = ({ filters, setFilters }) => {
  const isOpen = useSelector((state) => state.navMobile.isOpen);
  const dispatch = useDispatch();

  const handleTags = (name) => {
    const isIncluded = filters.includes(name);
    if (isIncluded) {
      const newFilters = filters.filter((tag) => tag !== name);
      setFilters([...newFilters]);
    } else {
      setFilters([...filters, name]);
    }
  };

  const selectedTag = (name, filters) => {
    const isIncluded = filters.includes(name);
    if (isIncluded) {
      return 'highlight';
    } else {
      return '';
    }
  };
  const handleAllTags = () => {
    setFilters([]);
    dispatch(close());
  };

  const handleCloseTags = () => {
    dispatch(close());
  };

  const mapTags = (tagVariables, tags) => {
    return tagVariables.map((str) => {
      const tag = tags[str];
      if (tag) {
        return (
          <li
            key={tag.name + '-tag'}
            onClick={() => handleTags(tag.name)}
            className={selectedTag(tag.name, filters)}
          >
            {/* <div>{tag.src ? <img src={tag.src} alt={tag.alt} /> : null}</div> */}
            <span>{tag.name}</span>
          </li>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div
      className={`p-l-mobile projects-list-tags ${isOpen ? 'show' : 'hidden'}`}
    >
      <div>
        <div onClick={handleCloseTags}>
          <span>CLOSE TAGS</span>

          <FontAwesomeIcon icon={faAngleDown} />
        </div>
        <ul className="tags">
          <li onClick={handleAllTags}>
            <span>ALL TAGS</span>
          </li>
          {mapTags(tagVariables, tags)}
        </ul>
      </div>
    </div>
  );
};

export default TagsDropdown;

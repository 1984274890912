import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DefaultImg from '../../assets/img/logo.png';

const Summary = ({ data, show, setShowSummary, setSelected }) => {
  const { src, desc, title, git, live, codepen, date } = data;

  let imgSrc = src;
  if (!src) {
    imgSrc = DefaultImg;
  }
  const linkTypes = [git, live, codepen];
  const links = (type, idx) => {
    if (type) {
      return (
        <li key={type + idx}>
          <a href={type} target={'_blank'}>
            {type}
          </a>
        </li>
      );
    }
  };
  return (
    <div
      className={`p-l-s-c-mobile projects-list-summary-container ${
        show ? 'show' : 'hidden'
      }`}
    >
      <div
        className="close-btn close-btn-mobile"
        onClick={() => {
          setShowSummary(false);
          setSelected(null);
        }}
      >
        <button>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className={``}>
        <div>
          <div className="projects-list-summary">
            <div className="summary-img-container">
              <img src={imgSrc} alt="screenshot of the web app" />
            </div>
            <h2>{title}</h2>
            <span>{date}</span>
            <span>{desc}</span>
            <ul>
              {data.tags.map((tag, idx) => (
                <li key={tag + idx}> {tag} </li>
              ))}
            </ul>
            <ul>{linkTypes.map((type) => links(type))}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;

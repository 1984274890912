import React from 'react';
import '../../stylesheets/home/sections.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedinIn,
  faGithubAlt,
  faCodepen,
} from '@fortawesome/free-brands-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import LinkWrap from '../general/aElCSS';

const Section = () => {
  const isMobile = useSelector((state) => state.mobile.isMobile);

  return (
    <div
      className={`profile ${isMobile ? 'home-section-mobile' : ''}`}
      id="profile"
    >
      <div className="main-section profile-container">
        <h1>{`{ Profile }`}</h1>
        <ul className="profile-links">
          <li className="icon linkedin">
            <LinkWrap data={profiles.linkedIn} />
          </li>

          <li className="icon facebook">
            <LinkWrap data={profiles.resume} />
          </li>
          <li className="icon github">
            <LinkWrap data={profiles.github} />
          </li>
          <li className="icon codepen">
            <LinkWrap data={profiles.codepen} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section;

const profiles = {
  resume: {
    className: '',
    href: 'https://drive.google.com/file/d/1DQfsAxYQLonJOrJDXrxeuOpDQIJEAjNL/view',
    targetBlank: true,
    component: () => {
      return <FontAwesomeIcon icon={faFile} size="2x" />;
    },
  },
  linkedIn: {
    className: '',
    href: 'https://www.linkedin.com/in/catherine-yang-0ab40b3a/',
    targetBlank: true,
    component: () => {
      return <FontAwesomeIcon icon={faLinkedinIn} size="2x" />;
    },
  },
  github: {
    className: '',
    href: 'https://github.com/liahnee',
    targetBlank: true,
    component: () => {
      return <FontAwesomeIcon icon={faGithubAlt} size="2x" />;
    },
  },
  codepen: {
    className: '',
    href: 'https://codepen.io/liahnee',
    targetBlank: true,
    component: () => {
      return <FontAwesomeIcon icon={faCodepen} size="2x" />;
    },
  },
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeartBroken,
  faInfoCircle,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import '../../../stylesheets/playground/recipes.css';

const FavMeal = ({ meal, setMealInfo, toggleFav }) => {
  return (
    <li>
      <img
        src={meal.strMealThumb}
        alt={meal.strMeal}
        onClick={() => setMealInfo(meal)}
      />
      <span>{meal.strMeal}</span>
      <span className="fav-clear" onClick={() => toggleFav(meal)}>
        <FontAwesomeIcon icon={faHeartBroken} />
      </span>
      <div className="hover-to-show">
        <div className="recipe-info-btn">
          <FontAwesomeIcon
            icon={faInfoCircle}
            onClick={() => setMealInfo(meal)}
          />
        </div>
      </div>
    </li>
  );
};
export default FavMeal;

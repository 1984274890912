import React, { useEffect, useState } from 'react';
import '../stylesheets/projectsListMobile.css';
import { projects } from '../assets/data/tags';
import TagsDropdown from '../components/navMobile/codesDropdown';
import DefaultImg from '../assets/img/logo.png';

import Summary from '../components/projectsListMobile/summary';

const ProjectsListMobile = () => {
  // state for lists
  const [selected, setSelected] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setShowSummary(false);
  }, [filters]);

  const handleClick = (data) => {
    setSelected(data);
    setShowSummary(true);
  };
  const mapProjects = (projects, filters) => {
    if (filters.length === 0) {
      return projects.map((data, idx) => {
        let imgSrc = data.src;
        let alt = data.alt;
        if (!data.src || data.src === '') {
          imgSrc = DefaultImg;
          alt = 'no screenshot for the project';
        }
        return (
          <div
            className={`projects-list-project-container ${
              selected == data ? 'selected-project' : ''
            }`}
            key={data.title + '-project' + idx}
            onClick={() => {
              handleClick(data);
            }}
            alt={alt}
          >
            <div
              className="projects-list-img-container"
              style={{
                backgroundImage: `url(${imgSrc})`,
                backgroundColor: 'black',
              }}
            ></div>
            <p>{data.title}</p>
          </div>
        );
      });
    } else {
      return projects.map((data, idx) => {
        let doesApply = false;
        for (let i = 0; i < filters.length; i++) {
          if (data.tags.includes(filters[i])) {
            doesApply = true;
            break;
          }
        }
        if (!doesApply) {
          return;
        }
        let imgSrc = data.src;
        let alt = data.alt;
        if (!data.src) {
          imgSrc = DefaultImg;
          alt = 'no screenshot for the project';
        }
        return (
          <div
            className="projects-list-project-container"
            key={data.title + '-project' + idx}
            alt={alt}
          >
            <div
              className="projects-list-img-container"
              onClick={() => {
                handleClick(data);
              }}
              style={{ backgroundImage: `url(${imgSrc}` }}
            ></div>
            <p>{data.title}</p>
          </div>
        );
      });
    }
  };

  return (
    <div style={{ display: 'flex' }} className="main-c">
      <div className="projects-list-container">
        <TagsDropdown filters={filters} setFilters={setFilters} />
        <div className="projects-list-main-container">
          <div className="projects-list-projects-container">
            {mapProjects(projects, filters)}
          </div>
        </div>
        {selected ? (
          <Summary
            data={selected}
            show={showSummary}
            setShowSummary={setShowSummary}
            setSelected={setSelected}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProjectsListMobile;

import React from 'react';
import { Link } from 'react-router-dom';
import '../stylesheets/navMobile.css';
import data from '../assets/data/nav';
import { useDispatch, useSelector } from 'react-redux';
import { open, close, newPage } from '../redux/navMobileSlice';

const Search = () => {
  const dispatch = useDispatch();
  const prevPage = useSelector((state) => state.navMobile.prevPage);
  const isOpen = useSelector((state) => state.navMobile.isOpen);
  const onPage = useSelector((state) => state.navMobile.onPage);

  const handleClick = (data) => {
    const { href } = data;
    if (href === '/' && onPage !== '/') {
      dispatch(close());
    } else if (isOpen && onPage === href) {
      dispatch(close());
    } else if (onPage !== href) {
      dispatch(open());
    } else if (!isOpen) {
      dispatch(open());
    }
    dispatch(newPage(href));
  };

  return (
    <React.Fragment>
      <nav id="mobile-nav">
        <ul className="" id="">
          {data.map((data) => {
            const { href, component } = data;
            return (
              <div
                key={data.href + '-key'}
                onClick={() => handleClick(data)}
                className={isOpen && onPage === data.href ? 'selected' : ''}
              >
                <Link to={href} key={data.href}>
                  {component()}
                </Link>
              </div>
            );
          })}
        </ul>
      </nav>
    </React.Fragment>
  );
};
export default Search;

import React, { useEffect, useState } from 'react';
import '../../stylesheets/playground/timer.css';

const dDay = '14 Nov 2022';

const Timer = (props) => {
  const [days, setDays] = useState('0');
  const [hours, setHours] = useState('0');
  const [mins, setMins] = useState('0');
  const [seconds, setSeconds] = useState('0');

  useEffect(() => {
    function countdown() {
      const dDate = new Date(dDay);
      const currentDate = new Date();

      const time = (dDate - currentDate) / 1000;

      function formatTime(time) {
        return time < 10 ? `0${time}` : time;
      }

      setDays(formatTime(Math.floor(time / 3600 / 24)));
      setHours(formatTime(Math.floor(time / 3600) % 24));
      setMins(formatTime(Math.floor(time / 60) % 60));
      setSeconds(formatTime(Math.floor(time) % 60));
    }
    countdown();
    const repeatCount = setInterval(countdown, 1000);

    return () => clearInterval(repeatCount);
  }, []);

  return (
    <div className="timer-container">
      <div className="overlay">
        <h1> Days Until Nov 14</h1>
        <div className="countdown-container">
          <div className="countdown-el hours-c">
            <p className="big-text" id="days">
              {days}
            </p>
            <span>Days</span>
          </div>
          <div className="countdown-el hours-c">
            <p className="big-text" id="hours">
              {hours}
            </p>
            <span>Hours</span>
          </div>
          <div className="countdown-el hours-c">
            <p className="big-text" id="minutes">
              {mins}
            </p>
            <span>Minutes</span>
          </div>
          <div className="countdown-el hours-c">
            <p className="big-text" id="seconds">
              {seconds}
            </p>
            <span>Seconds</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;

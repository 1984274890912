import React from 'react';
import '../../../stylesheets/playground/recipes.css';

const MealInfo = ({ meal }, props) => {
  //render components

  const renderMealInfo = (mealData, key = mealData.idMeal + 'info') => {
    //update the Meal info
    let ingList = [];
    for (let i = 1; i <= 20; i++) {
      const ingredient = mealData['strIngredient' + i];
      const measurement = mealData['strMeasure' + i];
      if (ingredient) {
        ingList.push(
          <li key={key}>
            {measurement !== '' ? ingredient + ' - ' + measurement : ingredient}
          </li>
        );
      } else {
        break;
      }
    }

    return (
      <div key={key}>
        <h1>{mealData.strMeal}</h1>
        <img src={mealData.strMealThumb} alt={mealData.strMeal} />
        <p>{mealData.strInstructions}</p>
        <h3>Ingredients:</h3>
        <div>
          <ul>{ingList}</ul>
        </div>
      </div>
    );
  };

  return <div>{renderMealInfo(meal)}</div>;
};
export default MealInfo;

import { createSlice } from '@reduxjs/toolkit';

const initialState = { onPage: null, isOpen: false, prevPage: null };

const navMobileSlice = createSlice({
  name: 'navMobile',
  initialState,
  reducers: {
    newPage: (state, action) => {
      state.prevPage = state.onPage;
      state.onPage = action.payload;
    },
    open: (state, action) => {
      state.isOpen = true;
    },
    close: (state, action) => {
      state.isOpen = false;
    },
  },
});

export const { newPage, open, close } = navMobileSlice.actions;
export default navMobileSlice.reducer;

import React from 'react';
import '../../stylesheets/home/sections.css';
import { close } from '../../redux/navMobileSlice';
import { useSelector, useDispatch } from 'react-redux';

const Sidebar = (props) => {
  const { scroll } = props;

  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.navMobile.isOpen);

  const handleClick = (name) => {
    scroll(name);
    dispatch(close());
  };

  return (
    <div
      className={`home-mobile-anchor-container ${isOpen ? 'show' : 'hidden'}`}
      onClick={() => {
        dispatch(close());
      }}
    >
      <ul>
        {sidebarMenu.map((data, idx) => {
          const { href } = data;
          const name = href.slice(1);
          return (
            <li onClick={() => handleClick(name)} key={`scroll-link-${idx}`}>
              <span>{name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;

const sidebarMenu = [
  {
    href: '#greeting',
    // component: () => <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>,
    className: 'home-anchor',
    color: 'NAVY',
  },
  {
    href: '#about',
    // component: () => <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>,
    className: 'home-anchor',
    color: 'turquoise',
  },
  {
    href: '#codes',
    // component: () => <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>,
    className: 'home-anchor',
    color: 'darkcyan',
  },
  {
    href: '#profile',
    // component: () => <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>,
    className: 'home-anchor',
    color: 'antiquewhite',
  },
];

import React from 'react';
import '../../stylesheets/home/sections.css';

import DOCPIC from '../../assets/img/preview_doc.png';
import PG from '../../assets/img/playground_thumbnail.png';
import { useSelector } from 'react-redux';

const Section = () => {
  const isMobile = useSelector((state) => state.mobile.isMobile);

  return (
    <div
      className={`codess ${isMobile ? 'home-section-mobile' : ''}`}
      id="codes"
    >
      <div className="main-section">
        <h1>{`{ Codes & Playground}`}</h1>
        <div
          className={`lighter flex ${isMobile ? 'codes-section-mobile' : ''}`}
        >
          <a className="home-codes-img-container" href="codes">
            <img src={DOCPIC} alt="screenshot of a website" />
          </a>
          <p>
            <span>Codes Page</span>
            <br />
            Displays some of the codes written as a practice or projects.
          </p>
        </div>
        <div
          className={`lighter flex ${
            isMobile ? 'codes-section-mobile second' : ''
          }`}
        >
          <p>
            <span>Playground Page</span>
            <br />
            Embedded React codes with various functions such as saving notes in
            local storage, search through API, etc.
          </p>
          <a className="home-codes-img-container" href="playground">
            <img src={PG} alt="screenshot of a website" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Section;

import React from 'react';
import Timer from '../../components/playground/timer';
import Quiz from '../../components/playground/quiz';
import Recipes from '../../components/playground/recipe';
import Notes from '../../components/playground/notes';
// import ToDo from '../../components/playground/todo';
// import Movies from '../../components/playground/movies';
// import Canvas from '../../components/playground/canvas';
// import GithubCard from '../../components/playground/githubCard';
// import PasswordGenerator from '../../components/playground/passwordGenerator';
// import WeatherApp from '../components/playground/weather';

export const projects = [
  {
    title: 'Countdown Timer',
    desc: 'Shows days, hours, minutes, and seconds left until the a preset d-day.',
    comp: <Timer />,
  },
  {
    title: 'Multiple Choice Quiz',
    desc: 'Simple quiz app. Alerts score at the end. Auto reset.',
    comp: <Quiz />,
  },
  {
    title: 'Search Recipes',
    desc: 'Uses an API to search recipes, save to favorites with localstorage, and open recipes.',
    comp: <Recipes />,
  },
  {
    title: 'Notes',
    desc: 'Uses localstorage to create, read, update, and delete multiple notes.',
    comp: <Notes />,
  },
  // {
  //   title: 'ToDo List',
  //   desc: 'Some description about the project in javascript/react. Will be multiple lines. Not more than a couple.',
  //   comp: <ToDo />,
  // },
  // {
  //   title: 'Movies',
  //   desc: 'Some description about the project in javascript/react. Will be multiple lines. Not more than a couple.',
  //   comp: <Movies />,
  // },
  // {
  //   title: 'Github Profile Card',
  //   desc: 'Some description about the project in javascript/react. Will be multiple lines. Not more than a couple.',
  //   comp: <GithubCard />,
  // },
  // {
  //   title: 'Drawing App',
  //   desc: 'Some description about the project in javascript/react. Will be multiple lines. Not more than a couple.',
  //   comp: <Canvas />,
  // },
  // {
  //   title: 'Password Generator',
  //   desc: 'Some description about the project in javascript/react. Will be multiple lines. Not more than a couple.',
  //   comp: <PasswordGenerator />,
  // },
  // {
  //   title: 'Weather App',
  //   desc: 'Some description about the project in javascript/react. Will be multiple lines. Not more than a couple.',
  //   comp: <WeatherApp />,
  // },
];

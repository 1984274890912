import React, { useEffect, useState } from 'react';
import '../stylesheets/projectsList.css';
import { projects } from '../assets/data/tags';
import Tags from '../components/projectsList/tags';
import DefaultImg from '../assets/img/logo.png';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ProjectsList = () => {
  // state for lists
  const [selected, setSelected] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setShowSummary(false);
  }, [filters]);

  const handleClick = (data) => {
    setSelected(data);
    setShowSummary(true);
  };

  const mapProjects = (projects, filters) => {
    if (filters.length === 0) {
      return projects.map((data, idx) => {
        let imgSrc = data.src;
        let alt = data.alt;
        if (!data.src || data.src === '') {
          imgSrc = DefaultImg;
          alt = 'no screenshot for the project';
        }
        return (
          <div
            className={`projects-list-project-container ${
              selected === data ? 'selected-project' : ''
            }`}
            key={data.title + '-project' + idx}
            onClick={() => {
              handleClick(data);
            }}
          >
            <div
              className="projects-list-img-container"
              style={{
                backgroundImage: `url(${imgSrc})`,
                backgroundColor: 'black',
              }}
              alt={alt}
            >
              {/* <img src={imgSrc} alt={alt} /> */}
            </div>
            <p>{data.title}</p>
          </div>
        );
      });
    } else {
      return projects.map((data, idx) => {
        let doesApply = false;
        for (let i = 0; i < filters.length; i++) {
          if (data.tags.includes(filters[i])) {
            doesApply = true;
            break;
          }
        }
        if (!doesApply) {
          return null;
        }
        let imgSrc = data.src;
        let alt = data.alt;
        if (!data.src) {
          imgSrc = DefaultImg;
          alt = 'no screenshot for the project';
        }
        return (
          <div
            className="projects-list-project-container"
            key={data.title + '-project' + idx}
          >
            <div
              className="projects-list-img-container"
              onClick={() => {
                handleClick(data);
              }}
              style={{ backgroundImage: `url(${imgSrc}` }}
              alt={alt}
            >
              {/* <img src={imgSrc} alt={alt} /> */}
            </div>
            <p>{data.title}</p>
          </div>
        );
      });
    }
  };

  return (
    <div style={{ display: 'flex' }} className="main-c">
      <div className="projects-list-container">
        <Tags filters={filters} setFilters={setFilters} />
        <div className="projects-list-main-container">
          <div className="projects-list-projects-container">
            {mapProjects(projects, filters)}
          </div>
        </div>
        <div
          className={`projects-list-summary-container ${
            showSummary ? '' : 'hidden'
          }`}
        >
          {/* <div> */}
          <div
            className="close-btn"
            onClick={() => {
              setShowSummary(false);
              setSelected(null);
            }}
          >
            <button>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {displaySummary(selected, showSummary)}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

const displaySummary = (data, show) => {
  if (!data) {
    return;
  }
  const { src, desc, title, git, live, codepen, date } = data;
  let imgSrc = src;
  if (!src) {
    imgSrc = DefaultImg;
  }

  const linkTypes = [git, live, codepen];
  const links = (type) => {
    if (type) {
      return (
        <li>
          <a href={type} target={'_blank'} rel="noopener noreferrer">
            {type}
          </a>
        </li>
      );
    }
  };

  return (
    <div className={`projects-list-summary`}>
      <div className="summary-img-container">
        <img src={imgSrc} alt="screenshot of the web app" />
      </div>
      <h2>{title}</h2>
      <span>{date}</span>
      <span>{desc}</span>
      <ul>
        {data.tags.map((tag) => (
          <li> {tag} </li>
        ))}
      </ul>
      <ul>{linkTypes.map((type) => links(type))}</ul>
    </div>
  );
};

export default ProjectsList;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import '../../stylesheets/playground/recipes.css';
import FavMeal from './recipe/favoriteMeal';
import MealItem from './recipe/mealItem';
import MealInfo from './recipe/mealInfo';

const Recipes = () => {
  const [randMeal, setRandMeal] = useState(null);
  const [favList, setFavList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [mealInfo, setMealInfo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  // const [dragFav, setDragFav] = useState(false);
  const dragFav = false;

  useEffect(() => {
    getRandomMeal();
    let favs = getMealsLS();
    if (favs !== null) {
      renderFavMeals(favs);
      setFavList(favs);
    }
  }, []);

  //api calls to fetch data
  const getRandomMeal = async () => {
    let random = await fetch(
      'https://www.themealdb.com/api/json/v1/1/random.php'
    )
      .then((resp) => resp.json())
      .then((data) => {
        let allMeals = data.meals;
        let notFav = null;
        while (notFav == null && allMeals.length > 0) {
          if (!favList.includes(allMeals[0])) {
            notFav = allMeals[0];
          } else {
            allMeals.shift();
          }
        }
        if (!!notFav) {
          setRandMeal(notFav);
        } else {
          setRandMeal(allMeals[0]);
        }
      });
    return random;
  };
  // const getMealById = async (id) => {
  //   const data = await fetch(
  //     'https://www.themealdb.com/api/json/v1/1/lookup.php?i=' + id
  //   )
  //     .then((resp) => resp.json())
  //     .then((data) => data.meals[0]);
  //   return data;
  // };

  const getMealsBySearch = async (term) => {
    return await fetch(
      'https://www.themealdb.com/api/json/v1/1/search.php?s=' + term
    )
      .then((resp) => resp.json())
      .then((data) => {
        const meals = data.meals;
        setSearchList(meals);
        return meals;
      });
  };

  // CRUD favorite meals to localstorage
  const getMealsLS = () => {
    const meals = JSON.parse(localStorage.getItem('mealIds'));
    return meals === null ? [] : meals;
  };

  const addMealLS = (meal) => {
    const meals = getMealsLS();
    localStorage.setItem('mealIds', JSON.stringify([...meals, meal]));
  };

  const removeMealLS = (meal) => {
    const meals = getMealsLS();
    localStorage.setItem(
      'mealIds',
      JSON.stringify(meals.filter((m) => m.idMeal !== meal.idMeal))
    );
  };

  // helpers

  const toggleFav = (meal) => {
    let isFav = checkFav(meal, favList);

    if (isFav) {
      setFavList(favList.filter((mealLS) => mealLS !== meal));
      removeMealLS(meal);
    } else {
      setFavList([...favList, meal]);
      addMealLS(meal);
    }
  };

  const searchMeals = async (e) => {
    e.preventDefault();
    setRandMeal(null);
    await getMealsBySearch(searchTerm);
  };

  //renders components
  const renderFavMeals = (list) => {
    if (list.length > 0) {
      return favList.map((meal) => {
        return (
          <FavMeal
            meal={meal}
            setMealInfo={setMealInfo}
            toggleFav={toggleFav}
            key={meal.idMeal}
          />
        );
      });
    }
  };

  const renderRandomMeal = (meal) => {
    if (!!meal) {
      return (
        <MealItem
          meal={meal}
          isRandom={true}
          favList={favList}
          toggleFav={toggleFav}
          setMealInfo={setMealInfo}
        />
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const renderSearchList = (list) => {
    if (!list) {
      return <div id="recipe-no-result">No Search Result</div>;
    }
    return list.map((meal, idx) => {
      let isFav = checkFav(meal, favList);

      return (
        <MealItem
          meal={meal}
          favList={favList}
          isFav={isFav}
          toggleFav={toggleFav}
          setMealInfo={setMealInfo}
          key={meal.idMeal}
        />
      );
    });
  };

  return (
    <div className="recipes-container">
      <div className="mobile-container">
        <div
          className={`popup-container ${mealInfo ? '' : 'hidden'}`}
          id="meal-popup"
        >
          <div className="popup" id="popup">
            <div
              className={`clear ${mealInfo ? '' : 'hidden'}`}
              onClick={() => setMealInfo(null)}
            >
              <button className="clear-btn">X</button>
            </div>
            <div className="meal-info" id="meal-info">
              {mealInfo ? <MealInfo meal={mealInfo} /> : null}
            </div>
          </div>
        </div>
        <div className="mobile-container">
          <form className="header">
            <input
              id="search-term"
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
            <button type="submit" id="search" onClick={searchMeals}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <div id="fav-container" className={'fav-container'}>
            <h3>Favorite Meals</h3>
            <ul
              className="fav-meals"
              id="fav-meals"
              style={
                dragFav
                  ? { cursor: 'grabbing', userSelect: 'none' }
                  : { cursor: 'grab', userSelect: 'auto' }
              }
              // onMouseDown={mouseDownHandler}
            >
              {renderFavMeals(favList)}
            </ul>
            {favList.length === 0 ? <p>Add your favorite recipes!</p> : null}
          </div>
          <div className="meals" id="meals">
            {renderRandomMeal(randMeal)}
            {renderSearchList(searchList)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipes;

// scrolling favmeal list horizontally

// let pos = { top: 0, left: 0, x: 0, y: 0 };
// const favMealsEl = useRef(null);

// const mouseMoveHandler = useCallback((e, pos) => {
//   const dx = e.clientX - pos.x;
//   const dy = e.clientY - pos.y;

//   favMealsEl.current.scrollTop = pos.top - dy;
//   favMealsEl.current.scrollLeft = pos.left - dx;
// });

// const mouseDownHandler = (e) => {
//   setDragFav(true);
//   pos = {
//     left: favMealsEl.current.scrollLeft,
//     top: favMealsEl.current.scrollTop,
//     x: e.clientX,
//     y: e.clientY,
//   };
//   document.addEventListener('mouseup', mouseUpHandler);
// };

// const mouseUpHandler = () => {
//   setDragFav(false);
// };

// useEffect(() => {
//   if (dragFav) {
//     favMealsEl.current.addEventListener('mousemove', (e) =>
//       mouseMoveHandler(e, pos)
//     );
//   } else {
//     favMealsEl.current.removeEventListener('mousemove', (e) =>
//       mouseMoveHandler(e, pos)
//     );
//   }
// }, [dragFav]);

const checkFav = (meal, list) => {
  let isFav = false;
  list.forEach((item) => {
    if (item.idMeal == meal.idMeal) {
      isFav = true;
    }
  });
  return isFav;
};

import React, { useEffect, useState } from 'react';
import '../../stylesheets/home/sections.css';

import { useSelector } from 'react-redux';

const Section = () => {
  const isMobile = useSelector((state) => state.mobile.isMobile);

  const [greetings, setGreetings] = useState('');
  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12 && hour > 3) {
      setGreetings('Good Morning');
    } else if (hour < 21 && hour >= 12) {
      setGreetings('Good Afternoon');
    } else {
      setGreetings('Good Evening');
    }
  }, []);

  return (
    <div
      className={`greetings ${isMobile ? 'home-section-mobile' : ''}`}
      id="greeting"
    >
      <div className="main-section">
        <p className="greeting">{greetings}!</p>
        <span className="b1"> I'm Cathreine Yang, a developer. </span>
        <p>
          This is is a portfolio site built with ReactJS, Dart Sass, and React
          Redux.
          <br />
          {/* <span className="desc-text">Mobile desgin is in progress.</span> */}
        </p>
      </div>
    </div>
  );
};

export default Section;

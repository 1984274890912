import React, { useState } from 'react';
import '../stylesheets/playgroundMobile.css';
import PlaygoundDropdown from '../components/navMobile/playgroundDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { open } from '../redux/navMobileSlice';

const PlaygroundMobile = () => {
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.navMobile.isOpen);

  const renderProject = (selected) => {
    if (selected !== '') {
      return selected.comp;
    }
  };

  const handleClose = () => {
    setSelected('');
    dispatch(open());
  };

  return (
    <div className="projects-live-container playground-mobile">
      <PlaygoundDropdown
        setSelected={setSelected}
        selected={selected}
        isOpen={isOpen}
      />
      <div className="projects-live-project-container p-l-p-c-mobile">
        {renderProject(selected)}
        {selected !== '' ? (
          <div className="clear" onClick={handleClose}>
            <button className="clear-btn">X</button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PlaygroundMobile;

import React from 'react';

import '../stylesheets/playground.css';
// import { CursorContext } from '../App';

const Paintings = () => {
  // const context = useContext(CursorContext);
  // const { dispatch } = context;
  // const { href, component, className, targetBlank } = data;

  // const target = targetBlank ? '_blank' : '_self';
  // const classes = className ? '' : '';

  return (
    <div className="projects-live-container">
      <div className="projects-live-sidebar"></div>
      <div className="projects-live-project-container"></div>
    </div>
  );
};

export default Paintings;

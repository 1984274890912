import React, { useState } from 'react';
import '../stylesheets/playground.css';

import { projects } from '../assets/data/playground';

const Playground = () => {
  const [selected, setSelected] = useState('');

  const renderList = (data) => {
    const { title, desc } = data;
    return (
      <li onClick={() => setSelected(data)} key={title}>
        <div>
          <h1>{title}</h1>
          <p className="desc">{desc}</p>
        </div>
        <div></div>
      </li>
    );
  };

  const renderProject = (selected) => {
    if (selected !== '') {
      return selected.comp;
    }
  };

  const handleClose = () => {
    setSelected('');
  };

  return (
    <div className="projects-live-container">
      <div className="projects-live-sidebar">
        <ul className="fh fw">
          {projects.map((title) => {
            return renderList(title);
          })}
        </ul>
      </div>
      <div className="projects-live-project-container">
        {renderProject(selected)}
        {selected !== '' ? (
          <div className="clear" onClick={handleClose}>
            <button className="clear-btn">X</button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Playground;

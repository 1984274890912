import React from 'react';
import '../../stylesheets/home/sections.css';

import { useSelector } from 'react-redux';

const Section = () => {
  const isMobile = useSelector((state) => state.mobile.isMobile);

  return (
    <div
      className={`about ${isMobile ? 'home-section-mobile' : ''}`}
      id="about"
    >
      <div className="main-section">
        <h1>{`{ About }`}</h1>
        <div className="lighter">
          <p>
            Catherine was a full-time art teacher in Arizona before deciding to
            make a career change to coding. She has been enjoying web games for
            years, so learning to code was a pleasure.
          </p>
          <p>
            Catherine's current goal is to create a small web game of her own
            with NodeJS. One of her favorite games is Oxygen Not Inlcuded.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section;

import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../../stylesheets/playground/notes.css';
import { useSelector, useDispatch } from 'react-redux';
import { add, reset } from '../../redux/notesSlice';

import Note from './notes/note';

const Notes = () => {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.notes);

  const renderNotes = (notes) => {
    return notes.map((note, idx) => {
      return <Note note={note} idx={idx} />;
    });
  };

  const createNote = () => {
    dispatch(add());
  };

  return (
    <div className="notes-container fw">
      <div className="notes-toolbar">
        <button
          className="notes-tool"
          id="reset"
          onClick={() => dispatch(reset())}
        >
          <FontAwesomeIcon icon={faTrash} /> Reset
        </button>
        <span className="tool-border" />
        <button className="notes-tool" id="add" onClick={createNote}>
          <FontAwesomeIcon icon={faPlus} /> Add Note
        </button>
      </div>
      {renderNotes(notes)}
    </div>
  );
};

export default Notes;

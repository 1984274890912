import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './stylesheets/app.css';
import './stylesheets/header.css';
import './stylesheets/footer.css';
// import Logo from './assets/img/logo.png';

import Routes from './routes';
import RoutesMobile from './routesMobile';
import Nav from './components/nav';
import NavMobile from './components/navMobile';

import { useDispatch, useSelector } from 'react-redux';
import { turnMobile, turnNotMobile } from './redux/mobileSlice';
// import { onPage } from './redux/navMobileSlice';

export const CursorContext = createContext();

function App() {
  const [coordX, setCoordX] = useState(0);
  const [coordY, setCoordY] = useState(0);

  const isHovering = useSelector((state) => state.cursor.isHovering);
  const color = useSelector((state) => state.cursor.color);
  const isMobile = useSelector((state) => {
    return state.mobile.isMobile;
  });
  // const isMobile = true;
  const dispatch = useDispatch();

  const setRadialGradient = (e, isHovering) => {
    let rad = 15;
    if (isHovering) rad = 20;
    setCoordX(e.clientX - rad);
    setCoordY(e.clientY - rad);
  };

  function handleWindowSizeChange() {
    if (window.innerWidth < 800) {
      dispatch(turnMobile());
    } else {
      dispatch(turnNotMobile());
    }
  }

  useEffect(() => {
    if (window.innerWidth < 800 && !isMobile) {
      dispatch(turnMobile());
    }

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <Router>
      {isMobile ? (
        <div className="app">
          <RoutesMobile />
          <NavMobile />
        </div>
      ) : (
        <div
          className={`app cursor ${
            isHovering && color ? color.toLowerCase() : ''
          }`}
          onMouseMove={(e) => setRadialGradient(e, isHovering)}
          style={{ backgroundPosition: `${coordX}px ${coordY}px` }}
        >
          <header className="a row-container">
            <Nav />
          </header>
          <Routes />
        </div>
      )}
    </Router>
  );
}

export default App;

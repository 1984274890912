import React from 'react';
import '../../stylesheets/home/sections.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as solidCircle } from '@fortawesome/free-solid-svg-icons';

const Sidebar = (props) => {
  const { scroll, location } = props;
  return (
    <div>
      <nav className="area-a area left-profile">
        <ul className="anchors">
          {sidebarMenu.map((data, idx) => {
            const { href } = data;
            const name = href.slice(1);
            return (
              <li
                className={`${href === location ? 'tracker-in' : ''}`}
                onClick={() => scroll(name)}
                key={`scroll-link-${idx}`}
              >
                {href === location ? (
                  <FontAwesomeIcon icon={solidCircle}></FontAwesomeIcon>
                ) : (
                  <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;

const sidebarMenu = [
  {
    href: '#greeting',
    component: () => <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>,
    className: 'home-anchor',
    color: 'NAVY',
  },
  {
    href: '#about',
    component: () => <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>,
    className: 'home-anchor',
    color: 'turquoise',
  },
  {
    href: '#codes',
    component: () => <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>,
    className: 'home-anchor',
    color: 'darkcyan',
  },
  {
    href: '#profile',
    component: () => <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>,
    className: 'home-anchor',
    color: 'antiquewhite',
  },
];

import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../components/home/sidebar';
import Greeting from '../components/home/greeting';
import About from '../components/home/about';
import Codes from '../components/home/codes';
import Profile from '../components/home/profile';

import '../stylesheets/home.css';
const Home = (props) => {
  const greetingRef = useRef(null);
  const aboutRef = useRef(null);
  const codesRef = useRef(null);
  const profileRef = useRef(null);
  const currentRef = useRef(null);
  const homeRef = useRef(null);
  const [scrollPositions, setScrollPositions] = useState({});
  const [location, setLocation] = useState('#greeting');

  const executeScroll = (ref) => {
    switch (ref) {
      case 'greeting':
        currentRef.current = greetingRef;
        break;
      case 'about':
        currentRef.current = aboutRef;
        break;

      case 'codes':
        currentRef.current = codesRef;
        break;

      case 'profile':
        currentRef.current = profileRef;
        break;
      default:
        currentRef.current = greetingRef;
    }
    if (currentRef.current != null) {
      currentRef.current.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  useEffect(() => {
    setScrollPositions({
      greeting: greetingRef.current.offsetTop,
      about: aboutRef.current.offsetTop,
      codes: codesRef.current.offsetTop,
      profile: profileRef.current.offsetTop,
    });

    homeRef.current.onscroll = trackScroll;
  }, [window.innerWidth]);

  const trackScroll = (e) => {
    const positionY = e.target.scrollTop;
    if (positionY < scrollPositions.about && location !== '#greeting') {
      setLocation('#greeting');
      return;
    } else if (
      positionY >= scrollPositions.about &&
      positionY < scrollPositions.codes &&
      location !== '#about'
    ) {
      setLocation('#about');
      return;
    } else if (
      positionY >= scrollPositions.codes &&
      positionY < scrollPositions.profile &&
      location !== '#codes'
    ) {
      setLocation('#codes');
      return;
    } else if (
      positionY >= scrollPositions.profile &&
      location !== '#profile'
    ) {
      setLocation('#profile');
      return;
    }
  };
  return (
    <div className="home" ref={homeRef} onScroll={trackScroll}>
      <Sidebar
        scroll={executeScroll}
        scrollPositions={scrollPositions}
        location={location}
      />
      <div className="">
        <div ref={greetingRef}>
          <Greeting />
        </div>
        <div ref={aboutRef}>
          <About />
        </div>
        <div ref={codesRef}>
          <Codes />
        </div>
        <div ref={profileRef}>
          <Profile />
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from 'react';
import '../stylesheets/nav.css';
import LinkWrap from './general/aElCSS';
import data from '../assets/data/nav';
import { useDispatch } from 'react-redux';
import { enter, exit, switchColor } from '../redux/cursorSlice';

const Search = () => {
  const dispatch = useDispatch();
  //   const [state, dispatch] = useReducer(cursorReducer, cursorState);
  // const state = useSelector(state => {
  // return {
  //     selected: state.reducerFilter.selected,
  //     unselected: state.reducerFilter.unselected,
  // }
  // });
  return (
    <React.Fragment>
      {/* <div className="area-d area">
        <img src={Img} alt="logo" className="logo-img-s" />
      </div> */}
      <nav className="tabs-container row-container area-e">
        <ul className="area-b tabs area" id="tabs">
          {data.map((data) => {
            const { color } = data;
            return (
              <div
                onMouseEnter={() => {
                  dispatch(switchColor(color));
                  dispatch(enter());
                }}
                onMouseLeave={() => {
                  dispatch(exit());
                }}
                key={data.href + '-key'}
              >
                <LinkWrap data={data} key={data.href} />
              </div>
            );
          })}
        </ul>
      </nav>
    </React.Fragment>
  );
};
export default Search;

import React from 'react';
import {
  // faCaretRight,
  faHome,
  faCode,
  faPaw,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../stylesheets/nav.css';

const data = [
  {
    color: 'PINK',
    href: '/',
    icon: faHome,
    name: 'Home',
    component: () => {
      return (
        <li>
          <FontAwesomeIcon icon={faHome} />
          <span className="nav-text">Home</span>
        </li>
      );
    },
  },
  {
    color: 'BLACK',
    href: '/codes',
    icon: faCode,
    name: 'Codes',
    component: () => {
      return (
        <li>
          <FontAwesomeIcon icon={faCode} />
          <span className="nav-text">Codes</span>
        </li>
      );
    },
  },
  {
    color: 'YELLOW',
    href: '/playground',
    icon: faPaw,
    name: 'Playground',
    component: () => {
      return (
        <li>
          <FontAwesomeIcon icon={faPaw} />
          <span className="nav-text">Playground</span>
        </li>
      );
    },
  },
  // {
  //   color: 'PINK',
  //   href: '/paintings',
  //   component: () => {
  //     return (
  //       <li>
  //         <FontAwesomeIcon icon={faPaintBrush} />
  //         <span className="nav-text">Paintings</span>
  //       </li>
  //     );
  //   },
  // },
];

export default data;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import mobileReducer from './mobileSlice';
import cursorReducer from './cursorSlice';
import navMobileReducer from './navMobileSlice';
import notesReducer from './notesSlice';

const rootReducer = combineReducers({
  mobile: mobileReducer,
  cursor: cursorReducer,
  navMobile: navMobileReducer,
  notes: notesReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

import WHR from '../img/whr_front.png';
import ST from '../img/sentT.png';
import AC from '../img/adopt.png';
import Tree from '../img/tree.png';
import SE from '../img/SE.png';
import PPro from '../img/preview_pro.png';
import PDoc from '../img/preview_doc.png';
import PSur from '../img/preview_sur.png';
import PTri from '../img/preview_tri.png';
import Por from '../img/por.png';
import CFA from '../img/cfa.png';
import NavCRE from '../img/navcre.png';
import Trivia from '../img/tandem_front.png';
import Breathe from '../img/breathe.png';
import Weather from '../img/weather-app.png';

export const tags = {
  react: {
    name: 'React',
    src: '',
    alt: '',
    searchable: [],
  },
  ror: {
    name: 'Ruby on Rails',
    src: '',
    alt: '',
    searchable: [],
  },
  erb: {
    name: 'Embedded Ruby',
    src: '',
    alt: '',
    searchable: [],
  },
  js: {
    name: 'Javascript',
    src: '',
    alt: '',
    searchable: [],
  },
  // node: {
  //   name: 'Node',
  //   src: '',
  //   alt: '',
  //   searchable: [],
  // },
  hooks: {
    name: 'React Hooks',
    src: '',
    alt: '',
    searchable: [],
  },
  redux: {
    name: 'Redux',
    src: '',
    alt: '',
    searchable: [],
  },
  jwt: {
    name: 'JWT',
    src: '',
    alt: '',
    searchable: [],
  },
  activerecord: {
    name: 'Active Record',
    src: '',
    alt: '',
    searchable: [],
  },
  restapi: {
    name: 'Rest API',
    src: '',
    alt: '',
    searchable: [],
  },
  mvc: {
    name: 'MVC',
    src: '',
    alt: '',
    searchable: [],
  },
  sql: {
    name: 'SQL',
    src: '',
    alt: '',
    searchable: [],
  },
  graphql: {
    name: 'GraphQL',
    src: '',
    alt: '',
    searchable: [],
  },
  sass: {
    name: 'SASS',
    src: '',
    alt: '',
    searchable: [],
  },
  css: {
    name: 'CSS',
    src: '',
    alt: '',
    searchable: [],
  },
  html: {
    name: 'HTML',
    src: '',
    alt: '',
    searchable: [],
  },
  cfml: {
    name: 'CFML',
    src: '',
    alt: '',
    searchable: [],
  },
  coldbox: {
    name: 'Coldbox',
    src: '',
    alt: '',
    searchable: [],
  },
  live: {
    name: 'Live',
    src: '',
    alt: '',
    searchable: [],
  },
  jest: {
    name: 'Jest/React testing library',
    src: '',
    alt: '',
    searchable: [],
  },
  mobile: {
    name: 'Mobile Responsive Design',
    src: '',
    alt: '',
    searchable: [],
  },
};

export const tagVariables = Object.keys(tags);
const tagNames = {};
const createTagNames = () => {
  tagVariables.forEach((variable) => {
    tagNames[variable] = tags[variable].name;
  });
};
createTagNames();

const {
  react,
  ror,
  js,
  // node,
  hooks,
  redux,
  jwt,
  activerecord,
  restapi,
  mvc,
  graphql,
  sass,
  css,
  html,
  cfml,
  coldbox,
  sql,
  team,
  erb,
  live,
  jest,
  mobile,
} = tagNames;

export const projects = [
  // {
  //   title: '',
  //   src: '',
  //   desc: 'some lengthy description but in a sentence or two. brief purpose and whatever was used,',
  //   git: 'https://github.com/liahnee/breathe',
  //   live: '',
  //   tags: [react, js, html, graphql],
  // },

  {
    title: 'Weather App',
    src: Weather,
    date: 'June 2021',
    desc: 'Utilized Google map API for location search and images, CDN for weather icons, and OpenWeatherMap for weather cast.',
    git: '',
    live: 'https://liahnee-weather-app.netlify.app/',
    tags: [js, html, restapi, live, mobile],
  },
  {
    title: 'Trivia App',
    src: Trivia,
    date: 'Nov 2020',
    desc: 'Trivia app used with provided json data. Tracks scores with state and provides summary of selected answer choices and correct answer at the end of the round',
    git: 'https://github.com/liahnee/tandem-trivia',
    live: 'https://trivia-for-tandem.netlify.app/',
    tags: [react, hooks, js, live, sass, css, sass, jest, mobile],
  },
  {
    title: 'Practice: NavCRE',
    src: NavCRE,
    date: 'Nov 2020',
    desc: 'Practiced Sass. Redesigned NavCRE website content. Mobile responsive design',
    git: 'https://github.com/liahnee/navcre',
    live: 'https://navcre-code-challenge.netlify.app/',
    tags: [react, hooks, js, live, css, sass, redux, mobile],
  },
  {
    title: 'Practice: CFA',
    src: CFA,
    date: 'Aug 2020',
    desc: 'Designed and rendered a website for a company I worked at as an example.',
    git: 'https://github.com/liahnee/cfa',
    live: '',
    tags: [react, hooks, js, css, sass],
  },
  {
    title: 'Profile Page Version 1',
    src: Por,
    date: 'Jan 2020',
    desc: 'First portfolio site.',
    git: '',
    live: 'https://liahnee-v1.com/',
    tags: [react, hooks, js, html, mobile],
  },
  {
    title: 'Hackathon project: Breathe',
    src: Breathe,
    date: 'May 2021',
    desc: "First online hackathon project with three other team members. Mainly worked on designing and writing frontend with React. In addition, I also translated team members' Javascript codes to JSX.",
    git: 'https://github.com/liahnee/breathe',
    live: '',
    tags: [react, hooks, js, html, graphql, redux],
  },
  {
    title: 'Web Health Record',
    src: WHR,
    date: 'Nov 2019',
    alt: 'WHR page preview',
    desc: 'This project was built to mock a patient chart used in the primary care clinics. Users can create multiple patient profiles, their chief complaints, and visit chart for each complaint.The skill sets exercised with this project was implementing JWT and Redux for the React front-end and Ruby on Rails backend. Redux was used for the scalability of the app. Data massaging was also done for diagnosis which searches on ICD11 API.',
    git: 'https://github.com/liahnee/whr_frontend',
    live: '',
    tags: [react, js, redux, jwt, ror, restapi, activerecord, sql, mvc, redux],
  },
  {
    title: 'Sentimental Tweet',
    src: ST,
    date: 'Nov 2019',
    alt: 'Sentimental Tweet page preview',
    desc: 'This app calls on Tweeter API and runs sentiment API on their most recent tweets. Users can log in to create favorites and observe trends on their Tweet sentiment.This was a team project where I took a lead on the front-end. Using React and Semantic UI, I designed a thoughtful and seamless UI/UX designs.',
    git: 'https://github.com/liahnee/sentimentTweet',
    live: '',
    tags: [react, js, redux, ror, activerecord, jwt, restapi, sql, mvc, team],
  },
  {
    title: 'Grow a Tree',
    src: Tree,
    date: 'Oct 2019',
    alt: 'Grow a Tree page preview',
    desc: 'Users can select a random seed and watch it grow into different a tree as it is watered. The focus of the project was using vanilla CSS and vanilla JS with RESTFUL API and respond accordingly with different data sent from the backend.',
    git: 'https://github.com/liahnee/tree',
    live: '',
    tags: [ror, js, css, activerecord, restapi, sql, mvc],
  },
  {
    title: 'Adopt a Child',
    src: AC,
    date: 'Aug 2019',
    alt: 'Adope a Child page preview',
    desc: "This is a game app where a user can adopt a child of random stats and gender. Users can create a schedule for the child and how each schedule affected the child's status to figure out their characteristics. This project was entirely Ruby on Rails for the front and the back-end. This project has many backend calculations and conditions for a different set of characteristics the data holds for rendering and carrying out new calculations. Vanilla CSS was used for UI.",
    git: 'https://github.com/liahnee/child_rails_mod2',
    live: '',
    tags: [ror, erb, css, activerecord, restapi, sql, mvc],
  },
  {
    title: 'Schedule an Exam',
    src: SE,
    date: 'Jun 2020',
    alt: 'Schedule an Exam page preview',
    desc: 'Scheduler can select and exam, which will dynamically fetch the available dates and testing centers for the selected option. Receipt number is auto generated and scheduler is redirected to a confirmation page with saved data on submit. Coldfusion markup language, CFML, was a new language for me to try writing an app with. By using coldbox framework, I was able to create a backend with MVC model.',
    git: 'https://github.com/liahnee/ntn-takehome',
    live: '',
    tags: [cfml, coldbox, sql, mvc],
  },
  {
    title: 'HTML: Portfolio/Product Landing Page',
    src: PPro,
    date: 'Apr 2020',
    alt: 'Tribute page preview',
    desc: 'This page introduces different video contents created by a musician Song4B. The page utilizes z-index and pseudo-elements to create a layered background. HTML5 elements used include but are not limited to, header, main, nav, section, details, and iframe.',
    git: '',
    codepen: 'https://codepen.io/liahnee/full/RwPdevz',
    live: '',
    tags: [css, html],
  },
  {
    title: 'HTML: Documentation Page',
    src: PDoc,
    date: 'Apr 2020',
    alt: 'Tribute page preview',
    desc: 'The page summarizes basic foundation of SASS, referenced by sass-lang.com.',
    git: '',
    codepen: 'https://codepen.io/liahnee/full/GRJamWB',
    live: '',
    tags: [css, html],
  },
  {
    title: 'HTML: Survey Page',
    src: PSur,
    date: 'Apr 2020',
    alt: 'Tribute page preview',
    desc: 'This is a survey page to be filled out by a user regarding user experience on the website. The page explores multiple form elements as well as their attributes. Some of the elements included in the page are checkbox, radio, text-area, and dropdown. Clean and minimal images and colors were used to reduce distractions.',
    git: '',
    codepen: 'https://codepen.io/liahnee/full/wvaNbZz',
    live: '',
    tags: [css, html],
  },
  {
    title: 'HTML: Tribute Page',
    src: PTri,
    date: 'Apr 2020',
    alt: 'Tribute page preview',
    desc: 'This project was to create a tribute page for Cardinal Stephen Kim, who is one of the respected figures in modern Korean history. The page handles multiple HTML5 elements, which includes but are not limited to figure, fig-caption, details, section, blockquotes, hr, ul, and cite.',
    git: '',
    codepen: 'https://codepen.io/liahnee/full/PoqVRQK',
    live: '',
    tags: [css, html],
  },
];

import React, { useEffect } from 'react';
import '../../stylesheets/playgroundMobile.css';
import { useDispatch } from 'react-redux';
import { close, open } from '../../redux/navMobileSlice';
import { projects } from '../../assets/data/playground';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const PlaygroundDropdown = ({ setSelected, selected, isOpen }) => {
  const dispatch = useDispatch();

  const renderList = (data) => {
    const { title, desc } = data;

    return (
      <li
        onClick={() => {
          setSelected(data);
          dispatch(close());
        }}
        key={title}
      >
        <div>
          <h1>{title}</h1>
          <p className="desc">{desc}</p>
        </div>
        <div></div>
      </li>
    );
  };

  useEffect(() => {
    if (selected === '') {
      dispatch(open());
    }
  }, [isOpen]);

  return (
    <div
      className={`projects-live-sidebar playground-dropdown ${
        isOpen ? 'show' : 'hidden'
      }`}
    >
      <ul className="fh fw">
        {selected !== '' ? (
          <li onClick={() => dispatch(close())}>
            <span>CLOSE TAGS</span>

            <FontAwesomeIcon icon={faAngleDown} />
          </li>
        ) : null}

        {projects.map((title) => {
          return renderList(title);
        })}
      </ul>
    </div>
  );
};

export default PlaygroundDropdown;

import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import HomeMobile from './pages/homeMobile';
import ProjectsMobile from './pages/projectsListMobile';
import PlaygroundMobile from './pages/playgroundMobile';

const Routes = () => {
  return (
    <main>
      <Switch>
        <Route exact path="/">
          <HomeMobile />
        </Route>
        <Route path="/codes">
          <ProjectsMobile />
        </Route>
        <Route path="/playground">
          <PlaygroundMobile />
        </Route>
        <Redirect to="/" />
      </Switch>
    </main>
  );
};

export default Routes;

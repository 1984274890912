import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import '../../../stylesheets/playground/recipes.css';
import { useSelector } from 'react-redux';

const MealItem = (props) => {
  const [isFavLocal, setIsFav] = useState(false);
  const { meal, isRandom, setMealInfo, toggleFav, favList, isFav } = props;

  useEffect(() => {
    if (isFav) {
      setIsFav(true);
    }
  });

  return (
    <div className="meal">
      <div className="meal-header" id={meal.strMeal}>
        {isRandom ? <span className="random"> Random Recipe</span> : ``}
        <img
          src={meal.strMealThumb}
          alt={meal.strMeal}
          onClick={() => setMealInfo(meal)}
        />
        <div className="meal-body">
          <h4>{meal.strMeal}</h4>
          <button
            className={`fav-btn ${isFavLocal ? 'active' : ''}`}
            id="fav-btn"
            onClick={() => {
              toggleFav(meal, isFav);
              setIsFav(!isFavLocal);
            }}
          >
            <FontAwesomeIcon icon={faHeart} />
          </button>
        </div>
      </div>
    </div>
  );
};
export default MealItem;

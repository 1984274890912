import React from 'react';
const LinkWrap = ({ data }) => {
  const { href, component, className, targetBlank } = data;

  const target = targetBlank ? '_blank' : '_self';
  const classes = className ? '' : '';

  return (
    <a href={href} target={target} className={classes}>
      {component()}
    </a>
  );
};

export default LinkWrap;

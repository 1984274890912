import { createSlice } from '@reduxjs/toolkit';

const json = localStorage.getItem('notes');
const loadedTxt = json ? JSON.parse(localStorage.getItem('notes')) : [''];

const initialState = { notes: loadedTxt };

const notesSlice = createSlice({
  name: 'cursor',
  initialState,
  reducers: {
    add: (state) => {
      state.notes = [...state.notes, ''];
      console.log(state.notes);
      localStorage.setItem('notes', JSON.stringify(state.notes));
    },
    edit: (state, action) => {
      const { idx, text } = action.payload;
      state.notes[idx] = text;
      localStorage.setItem('notes', JSON.stringify(state.notes));
    },
    remove: (state, action) => {
      const { idx } = action.payload;
      state.notes.splice(idx, 1);
      localStorage.setItem('notes', JSON.stringify(state.notes));
    },
    reset: (state) => {
      state.notes = [''];
      localStorage.setItem('notes', JSON.stringify(state.notes));
    },
  },
});

export const { add, remove, edit, reset } = notesSlice.actions;
export default notesSlice.reducer;
